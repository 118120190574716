import { IssuanceType } from 'src/shared/constants/constants';
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';
import { AgentModel } from '../model';

export const updateAgentAPI = async (payload: AgentModel) => {
  const res = await apiPut(`agent/${payload.id}`, payload);
  return res.data;
};

export const getAgentAPI = async (payload: any) => {
  const res = await apiGet(
    `agent?page=${payload.page}&limit=${payload.limit}&search=${
      payload.search || ''
    }&active=${payload.active || ''}&status=${
      payload.status || ''
    }&issuanceType=${IssuanceType.BULK}`
  );
  return res.data;
};

export const deleteAgentAPI = async (payload: AgentModel) => {
  const res = await apiDelete(`agent/${payload.id}`);
  return res;
};

export const addAgentAPI = async (payload: AgentModel) => {
  const res = await apiPost(`agent`, payload);
  return res;
};
