import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import { getSingleIssuanceDataAPI } from 'src/api/getSingleIssuanceData.api';
import {
  deletePassengerAPI,
  deleteSingleIssuanceDetailsAPI,
  generatePolicyAPI,
  getAgentListAPI,
  saveQuotationDetailsAPI,
  saveSingleIssuanceDetailsAPI,
  sendQuoteAPI,
  viewQuotationAPI
} from '../api/SingleIssuance.api';
import {
  initalPaginationData,
  Pagination,
  PaginationResponseDto
} from 'src/core/model/pagination.dto';
import {
  AccordianFormDetails,
  Agent,
  InitialAccordianFormDetails,
  PassengerDetails,
  SingleIssuanceTableData
} from 'src/modules/Dashboard/model/singleIssuanceTableData';
import {
  GeneratePolicyResponseData,
  GeneratePolicyResponseInitialData,
  saveQuotationPayload,
  singleIssuanceTabel,
  SingleIssuanceTable,
  SingleIssuanceTableRow,
  ViewQuotationPayload
} from 'src/modules/Dashboard/model';
import { boolean } from 'yup';
import { singleIssuanceDataDto } from 'src/core/model/singleIssusanceData.dto';
import moment from 'moment';
import dayjs from 'dayjs';
import { API_STATUS_CODE } from 'src/shared/constants/constants';
import { getErrorMessage } from 'src/shared/utils/utils';

interface singleIssuanceStateVar {
  singleIssuanceData: PaginationResponseDto<SingleIssuanceTableData>;
  loading: boolean;
  tableLoading: boolean;
  isError: boolean;
  sendQuoteStatus: boolean;
  errorMessage: string;
  isSuccess: boolean;
  successMsg: string;
  formDetails: AccordianFormDetails;
  singleIssuanceApiResponse: any;
  agentList: any;
  generatePolicyRes: GeneratePolicyResponseData;
  saveQuotationRes: any;
  viewQuotation: any;
  sendPoliciesData: singleIssuanceDataDto<SingleIssuanceTable>;
}

const initialState: singleIssuanceStateVar = {
  loading: false,
  tableLoading: false,
  singleIssuanceData: initalPaginationData,
  isError: false,
  sendQuoteStatus: false,
  errorMessage: '',
  isSuccess: false,
  successMsg: '',
  formDetails: InitialAccordianFormDetails,
  singleIssuanceApiResponse: {},
  agentList: [],
  generatePolicyRes: {
    referenceId: '',
    generatedPolicies: []
  },
  saveQuotationRes: {},
  viewQuotation: {},
  sendPoliciesData: singleIssuanceTabel
};

export const selectorSingleIssuanceState = ({
  singleIssuanceData
}: RootState) => ({
  loading: singleIssuanceData?.loading,
  tableLoading: singleIssuanceData?.tableLoading,
  singleIssuanceData: singleIssuanceData?.singleIssuanceData,
  sendQuoteStatus: singleIssuanceData?.sendQuoteStatus,
  isError: singleIssuanceData?.isError,
  isSuccess: singleIssuanceData?.isSuccess,
  successMsg: singleIssuanceData?.successMsg,
  errorMessage: singleIssuanceData?.errorMessage,
  formDetails: singleIssuanceData?.formDetails,
  agentList: singleIssuanceData?.agentList,
  singleIssuanceApiResponse: singleIssuanceData?.singleIssuanceApiResponse,
  saveQuotationRes: singleIssuanceData?.saveQuotationRes,
  generatePolicyRes: singleIssuanceData?.generatePolicyRes,
  viewQuotation: singleIssuanceData?.viewQuotation,
  sendPoliciesData: singleIssuanceData?.sendPoliciesData
});

export const getSingleIssuanceData = createAsyncThunk(
  'singleIssuanceData/getSingleIssuanceData',
  async (payload: Pagination, { rejectWithValue }) => {
    try {
      const response = await getSingleIssuanceDataAPI(payload);
      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message || 'An unknown error occurred');
      }
    }
  }
);

export const saveSingleIssuance = createAsyncThunk(
  'singleIssuanceData/saveSingleIssuance',
  async (issuanceData: saveQuotationPayload, { rejectWithValue }) => {
    try {
      const response = await saveSingleIssuanceDetailsAPI(issuanceData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSingleIssuance = createAsyncThunk(
  'singleIssuanceData/deleteSingleIssuance',
  async (issuanceData: { referenceId: string }, { rejectWithValue }) => {
    try {
      const response = await deleteSingleIssuanceDetailsAPI(issuanceData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendQuote = createAsyncThunk(
  'singleIssuanceData/sendQuote',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await sendQuoteAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAgentList = createAsyncThunk(
  'singleIssuanceData/getAgentListData',
  async (pageData: object, { rejectWithValue }) => {
    try {
      const response = await getAgentListAPI(pageData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const generatePolicy = createAsyncThunk(
  'singleIssuanceData/generatePolicy',
  async (issuanceData: object, { rejectWithValue }) => {
    try {
      const response = await generatePolicyAPI(issuanceData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveQuotation = createAsyncThunk(
  'singleIssuanceData/saveQuotation',
  async (
    issuanceData: {
      totalCost: number;
      referenceId: string;
      selectedQuotations: [];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await saveQuotationDetailsAPI(issuanceData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const viewQuotation = createAsyncThunk(
  'singleIssuanceData/viewQuotation',
  async (issuanceData: ViewQuotationPayload, { rejectWithValue }) => {
    try {
      const response = await viewQuotationAPI(issuanceData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePassenger = createAsyncThunk(
  'singleIssuanceData/deletePassenger',
  async (
    issuanceData: { passengerId: string; key: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await deletePassengerAPI(issuanceData);
      return { ...response, data: issuanceData };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleIssuanceSlice = createSlice({
  name: 'singleIssuanceData',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFormDetails: (state, action) => {
      state.formDetails = action.payload;
    },
    resetViewQuotationData: (state: singleIssuanceStateVar) => {
      const resetValue = [];
      state.viewQuotation = resetValue;
    },
    setSendQuoteStatus: (state, action) => {
      state.sendQuoteStatus = action.payload ?? null;
    },
    clearSaveSingleApiResponse: (state, action) => {
      state.singleIssuanceApiResponse = action.payload ?? null;
    },
    setIsError: (state, action) => {
      state.isError = action?.payload;
    },
    setErrorMsg: (state, action) => {
      state.errorMessage = action.payload;
    },
    setSuccessMsg: (state, action) => {
      state.successMsg = action.payload;
    },
    setIsSuccess: (state, action) => {
      state.isSuccess = action?.payload;
    },
    resetSingleTableData: (state: singleIssuanceStateVar) => {
      const resetData = initalPaginationData;
      state.singleIssuanceData = resetData;
    },
    setCheckedValueUpdate: (state, action) => {
      const { id, sentToAgent, sentToPassenger } = action.payload;

      state?.sendPoliciesData?.passengers?.forEach((passenger) => {
        if (passenger.id === id) {
          passenger.sendToAgent = sentToAgent;
          passenger.sendToPassenger = sentToPassenger;
        }
      });
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        getSingleIssuanceData.pending,
        (state: singleIssuanceStateVar) => {
          state.tableLoading = true;
          state.isError = false;
          state.isSuccess = false;
          state.errorMessage = '';
          state.successMsg = '';
        }
      )
      .addCase(
        getSingleIssuanceData.fulfilled,
        (state: singleIssuanceStateVar, action) => {
          state.tableLoading = false;
          if (action.payload) {
            state.singleIssuanceData = action.payload;
          }
        }
      )
      .addCase(
        getSingleIssuanceData.rejected,
        (state: singleIssuanceStateVar) => {
          state.tableLoading = false;
        }
      )
      .addCase(saveSingleIssuance.pending, (state: singleIssuanceStateVar) => {
        state.loading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(
        saveSingleIssuance.fulfilled,
        (state: singleIssuanceStateVar, action) => {
          state.loading = false;
          if (action.payload.data) {
            state.singleIssuanceApiResponse = action.payload.data;
            state.formDetails = {
              ...state.formDetails,
              referenceId: action?.payload?.data?.data?.referenceId,
              passengerDetails: state.formDetails?.passengerDetails.map(
                (passenger) => {
                  const passengerId =
                    action?.payload?.data?.data?.passengers?.find(
                      (pax) =>
                        pax.name === passenger?.name &&
                        pax.age === passenger?.age
                    )?.id;
                  return {
                    ...passenger,
                    id: passengerId
                  };
                }
              )
            };

            // If success
            if (action.payload.data.status) {
              state.isSuccess = true;
              state.successMsg = action.payload.data.message || '';
            }
          }

          // If error occoured
          if (!action.payload.data.status) {
            state.isError = true;
            state.errorMessage = getErrorMessage();
          }
        }
      )
      .addCase(
        saveSingleIssuance.rejected,
        (state: singleIssuanceStateVar, action: any) => {
          state.loading = false;
          state.isError = true;
          state.errorMessage = getErrorMessage({
            statusCode: action.payload.statusCode
          });
        }
      )
      .addCase(viewQuotation.pending, (state: singleIssuanceStateVar) => {
        state.loading = true;
      })
      .addCase(
        viewQuotation.fulfilled,
        (state: singleIssuanceStateVar, action) => {
          state.loading = false;
          if (action.payload.data) {
            state.viewQuotation = action.payload.data;
            state.singleIssuanceApiResponse = action.payload.data;

            // find required details
            const { agent, commonDetails } =
              state.singleIssuanceData?.records?.find(
                (record) =>
                  record?.referenceId ===
                  action.payload?.data?.data?.referenceId
              );

            // Set formDetails data
            state.formDetails = {
              ...InitialAccordianFormDetails,
              agent: {
                tjId: agent?.tjAgentId || '',
                name: agent?.name || '',
                email: agent?.email || '',
                mobileNumber: agent?.mobileNumber || ''
              },
              geoLocation: commonDetails?.geoLocation || '',
              numberOfPassengers: commonDetails?.numberOfPassengers || 0,
              departureDate: commonDetails?.tripStartDate
                ? dayjs(commonDetails.tripStartDate)
                : null,
              returnDate: commonDetails?.tripEndDate
                ? dayjs(commonDetails.tripEndDate)
                : null,
              noOfDays:
                commonDetails?.tripStartDate && commonDetails?.tripEndDate
                  ? moment(commonDetails.tripEndDate).diff(
                      moment(commonDetails.tripStartDate),
                      'days'
                    ) + 1
                  : 0
            };

            // set passengers details
            state.formDetails = {
              ...state.formDetails,
              referenceId: action?.payload?.data?.data?.referenceId,
              passengerDetails: action.payload?.data?.data?.passengers?.map(
                (passenger) => ({
                  ...passenger,
                  birthDate: dayjs(passenger?.dateofBirth)
                })
              )
            };
          }
        }
      )
      .addCase(viewQuotation.rejected, (state: singleIssuanceStateVar) => {
        state.loading = false;
      })
      .addCase(
        deleteSingleIssuance.pending,
        (state: singleIssuanceStateVar) => {
          state.loading = true;
        }
      )
      .addCase(
        deleteSingleIssuance.fulfilled,
        (state: singleIssuanceStateVar, action) => {
          state.loading = false;
        }
      )
      .addCase(
        deleteSingleIssuance.rejected,
        (state: singleIssuanceStateVar) => {
          state.loading = false;
        }
      )
      .addCase(sendQuote.pending, (state: singleIssuanceStateVar) => {
        state.loading = true;
      })
      .addCase(sendQuote.fulfilled, (state: singleIssuanceStateVar, action) => {
        state.loading = false;
        if (action.payload.data) {
          state.sendQuoteStatus = true;
        }
      })
      .addCase(sendQuote.rejected, (state: singleIssuanceStateVar) => {
        state.loading = false;
      })
      .addCase(getAgentList.pending, (state: singleIssuanceStateVar) => {
        state.loading = true;
      })
      .addCase(
        getAgentList.fulfilled,
        (state: singleIssuanceStateVar, action) => {
          state.loading = false;
          if (action.payload.data) {
            state.agentList = action.payload.data.records;
          }
        }
      )
      .addCase(getAgentList.rejected, (state: singleIssuanceStateVar) => {
        state.loading = false;
      })
      .addCase(generatePolicy.pending, (state: singleIssuanceStateVar) => {
        state.loading = true;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(
        generatePolicy.fulfilled,
        (state: singleIssuanceStateVar, action) => {
          state.loading = false;
          if (action.payload.data?.data) {
            const updatedGeneratedPolicies =
              action.payload?.data?.data?.generatedPolicies?.map((policy) => ({
                ...policy,
                id: policy.details.passengerId,
                details: {
                  ...policy.details,
                  id: policy.details.passengerId
                }
              }));

            state.generatePolicyRes = {
              ...action.payload.data?.data,
              generatedPolicies: updatedGeneratedPolicies
            };

            if (action.payload.data.status) {
              state.isSuccess = true;
              state.successMsg = action.payload.data?.message;
            }
          }

          // If error ooccoured
          if (!action.payload.data.status) {
            state.isError = true;
            state.errorMessage = action.payload.data?.message;
          }
        }
      )
      .addCase(
        generatePolicy.rejected,
        (state: singleIssuanceStateVar, action: any) => {
          state.loading = false;

          if (
            action.payload?.statusCode ===
            Number(API_STATUS_CODE.INTERNAL_SERVER_ERRROR)
          ) {
            state.errorMessage =
              'Some error has occurred. Please fill out all input fields and try again. If the problem persists, contact support.';
          } else {
            state.errorMessage = action?.payload?.message
              ? action?.payload?.message
              : '';
          }
        }
      )
      .addCase(saveQuotation.pending, (state: singleIssuanceStateVar) => {
        state.loading = true;
        state.isSuccess = false;
      })
      .addCase(
        saveQuotation.fulfilled,
        (
          state: singleIssuanceStateVar,
          action: PayloadAction<any, string, { arg: saveQuotationPayload }>
        ) => {
          state.loading = false;
          if (action.payload.data) {
            // state.generatePolicyRes = action.payload.data;
            state.isSuccess = true;
            state.successMsg = action.payload?.data?.message;

            // Update the table data
            if (action.payload.data.status) {
              if (state.singleIssuanceData?.records) {
                state.singleIssuanceData.records =
                  state.singleIssuanceData.records.map((issuance) => {
                    if (
                      issuance?.referenceId === action.meta.arg?.referenceId
                    ) {
                      const quote = {
                        totalCost: String(action.meta.arg?.totalCost)
                      };
                      return {
                        ...issuance,
                        quotations: quote
                      };
                    }
                    return issuance;
                  });
              }
            }
          }
        }
      )
      .addCase(saveQuotation.rejected, (state: singleIssuanceStateVar) => {
        state.loading = false;
      })
      .addCase(deletePassenger.pending, (state: singleIssuanceStateVar) => {
        state.loading = true;
      })
      .addCase(deletePassenger.fulfilled, (state: any, action) => {
        state.loading = false;
        if (action.payload.status) {
          const paxKey = action.payload.data.key;
          state.formDetails.passengerDetails =
            state.formDetails.passengerDetails.filter(
              (passenger) => passenger.key !== paxKey
            );
          state.formDetails.numberOfPassengers =
            state.formDetails.passengerDetails.length;
        }
      })
      .addCase(deletePassenger.rejected, (state: singleIssuanceStateVar) => {
        state.loading = false;
      });
  }
});

export const {
  setFormDetails,
  clearSaveSingleApiResponse,
  setSendQuoteStatus,
  setIsError,
  setIsSuccess,
  setErrorMsg,
  setSuccessMsg,
  resetSingleTableData,
  setCheckedValueUpdate,
  setLoading,
  resetViewQuotationData
} = singleIssuanceSlice.actions;
export default singleIssuanceSlice.reducer;
