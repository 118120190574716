import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  customErrorMessages,
  databaseErrorMessages,
  GEO_LOCATIONS,
  httpErrorMessages
} from '../constants/constants';
import { singleIssuanceDataDto } from 'src/core/model/singleIssusanceData.dto';
import {
  GeneratedPolicy,
  SingleIssuanceTable,
  SingleIssuanceTableRow
} from 'src/modules/Dashboard/model';

//To convert any string to title case
/**
 * @param value
 * @returns title case string
 */
export const titleCase = (value: string) => {
  if (!value) {
    return '';
  }
  return value.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  );
};

//To convert date string in specific format, by default MM/DD/YYYY
/**
 * @param date
 * @param format optional
 * @returns formated date
 */
export const formatDate = (date: string, format?: string) => {
  return date && dayjs(date).isValid()
    ? dayjs(date).format(format ? format : 'MM/DD/YYYY')
    : '';
};

//To convert date string to UTC in specific format, by default MM/DD/YYYY
/**
 * @param date
 * @param format optional
 * @returns formated utc date
 */
export const formatDateToUtc = (date: string, format?: string) => {
  dayjs.extend(utc);
  return dayjs(date).isValid()
    ? dayjs(date)
        .utc(true)
        .format(format ? format : 'MM/DD/YYYY')
    : '';
};

//To extract time from date string in specific format, by default hh:mm a
/**
 * @param date
 * @param format optional
 * @returns formated time
 */
export const getTimeStamp = (date: string, format?: string) => {
  return dayjs(date).isValid()
    ? dayjs(date).format(format ? format : 'hh:mm a')
    : '';
};

//Get the initials of any string value
/**
 * @param text
 * @returns initials of given text
 */
export const getInitials = (text: string) => {
  return text
    ? text
        .split(' ')
        .map((v) => v.slice(0, 1))
        .join('')
        .toUpperCase()
    : '';
};

//Remove underscore from any string value
/**
 * @param text
 * @returns given string without underscore
 */
export const removeUnderscore = (text: string) => {
  return text
    ? text.replace(/(?:_| |\b)(\w)/g, function ($1) {
        return $1.toUpperCase().replace('_', ' ');
      })
    : '';
};

//To conver bytes to kb
/**
 * @param value in bytes
 * @returns converted value in kb
 */
export const convertBytesToKb = (value: number) => {
  return value ? Math.round(value / Math.pow(1024, 1)) : 0;
};

//To get the today's date
/**
 * @returns current date in YYYY-MM-DD format
 */
export const getDate = () => {
  const today = new Date();
  const dayNum = today.getDate();
  const monthNum = today.getMonth() + 1;
  const yearNum = today.getFullYear();
  let day = dayNum.toString();
  let month = monthNum.toString();
  if (dayNum < 10) {
    day = '0' + dayNum;
  }

  if (monthNum < 10) {
    month = '0' + monthNum;
  }
  return `${yearNum}-${month}-${day}`;
};

//To convert hexa code to rgba format
/**
 * @param hex
 * @param opacity
 * @returns converted rgba value
 */
export const hexToRgbA = (hex: string, opacity: number) => {
  let colorData: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    colorData = hex.substring(1).split('');
    if (colorData.length == 3) {
      colorData = [
        colorData[0],
        colorData[0],
        colorData[1],
        colorData[1],
        colorData[2],
        colorData[2]
      ];
    }
    colorData = '0x' + colorData.join('');
    return (
      'rgba(' +
      [(colorData >> 16) & 255, (colorData >> 8) & 255, colorData & 255].join(
        ','
      ) +
      `,${opacity})`
    );
  }
  throw new Error('Bad Hex');
};

//To get the comma seperated values from array of string
/**
 * @param data
 * @returns Comma seperated string
 */
export const getCommaSeperatedValues = (data: string[]) => {
  return data?.length > 0 ? data?.toString()?.replaceAll(',', ', ') : '';
};

export const customInternationDate2 = (dateString?: any) => {
  const dateObj = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  const formattedDate = dateObj.toLocaleDateString('en-us', options);
  return formattedDate;
};

export const dateDiffrence = (fromDate, toDate) => {
  const day1 = dayjs(fromDate);
  const day2 = dayjs(toDate);

  const years = Math.abs(day1.diff(day2, 'y'));
  let months = Math.abs(day1.diff(day2, 'M')) - years * 12;

  let diff = '';
  if (years > 0) {
    diff = years + ' yr ';
  }

  if (months > 0) {
    diff += months + ' mos';
  }

  return diff;
};

//Check if http:// or https:// is present in the url else add it.
export const validateUrl = (linkUrl: string) => {
  return /^(http|https):\/\/[^ "]+$/.test(linkUrl)
    ? linkUrl
    : 'http://' + linkUrl;
};

// Generates a URL for the given data using URL.createObjectURL.
/**
 * @param object
 * @returns URL.createObjectURL.
 */
export const generateDocumentUrl = (document: AxiosResponse): string => {
  const blob = new Blob([document.data]);
  return URL.createObjectURL(blob);
};

export const removeTextSpace = (text: string) => {
  return text?.replace(/\s+/g, '');
};
export type StatusOption = {
  label: string | '';
  value: string | '';
};

export const getPassengersFormFields = ({
  noOfPassengers,
  handleInputChange,
  departureDate
}) => {
  if (noOfPassengers === 0) {
    return;
  }
  let formFields = [];
  for (let i = 1; i <= noOfPassengers; i++) {
    const data = [
      {
        id: `passenger_${i}_name`,
        name: `passenger_${i}_name`,
        type: 'text',
        label: 'Passenger Name',
        placeholder: 'Enter name of passengers',
        gridColumns: 2,
        handleFieldChange: handleInputChange,
        validations: {
          required: true
        }
      },
      {
        id: `passengers_${i}_geography_location`,
        name: `passengers_${i}_geography_location`,
        type: 'select',
        label: 'Geography Location',
        placeholder: 'Select Geography Location',
        gridColumns: 2,
        options: GEO_LOCATIONS,
        itemValueKey: 'id',
        itemLabelKey: 'label',
        validations: {
          required: true
        },
        errorMessages: {
          requiredErrMsg: 'Geography location is required'
        },
        handleFieldChange: handleInputChange
      },
      {
        id: `passenger_${i}_departure_date`,
        name: `passenger_${i}_departure_date`,
        type: 'date',
        label: 'Departure Date',
        placeholder: '',
        gridColumns: 2,
        validations: {
          required: true
        },
        handleFieldChange: handleInputChange,
        disablePastDate: true
      },
      {
        id: `passenger_${i}_return_date`,
        name: `passenger_${i}_return_date`,
        type: 'date',
        label: 'Return Date',
        placeholder: '',
        gridColumns: 2,
        validations: {
          required: true
        },
        handleFieldChange: handleInputChange,
        disablePastDate: true,
        minDate: departureDate
      },
      {
        id: `passenger_${i}_birth_date`,
        name: `passenger_${i}_birth_date`,
        type: 'date',
        label: 'Birth Date',
        placeholder: '',
        gridColumns: 2,
        validations: {
          required: true
        },
        disableFutureDate: true,
        handleFieldChange: handleInputChange
      },
      {
        id: `passenger_${i}_age`,
        name: `passenger_${i}_age`,
        type: 'text',
        label: 'Age',
        placeholder: 'Enter Age',
        gridColumns: 2,
        validations: {
          required: false
        },
        isDisabled: true,
        handleFieldChange: handleInputChange
      }
    ];
    formFields.push(...data);
  }

  return formFields;
};

export const getPassengerInitialValues = ({
  noOfPassengers,
  departureDate,
  returnDate,
  passengerDetails = []
}) => {
  let initialValues = [];
  for (let i = 1; i <= noOfPassengers; i++) {
    const val = {
      [`passenger_${i}_key`]: passengerDetails[i - 1]?.key || i,
      [`passenger_${i}_name`]: passengerDetails[i - 1]?.name,
      [`passengers_${i}_geography_location`]: GEO_LOCATIONS[0]?.id,
      [`passenger_${i}_departure_date`]: departureDate,
      [`passenger_${i}_return_date`]: returnDate,
      [`passenger_${i}_birth_date`]: passengerDetails[i - 1]?.birthDate,
      [`passenger_${i}_age`]: passengerDetails[i - 1]?.age
    };

    initialValues.push(val);
  }

  return initialValues;
};

export const calculateAge = (birthdate) => {
  const today = new Date();
  const birthDate = new Date(birthdate);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Adjust age if the birthday has not occurred this year
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const downloadFile = (
  data: any,
  fileName: string,
  fileExtension: string
) => {
  try {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.${fileExtension}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    return true;
  } catch (error) {
    console.error('Error while downloading file', error);
    return false;
  }
};

export function getDaysDifference(date1, date2) {
  let departure_date = new Date(date1);
  let return_date = new Date(date2);
  // Convert the dates to milliseconds
  const millisecondsDifference = Math.abs(
    departure_date.getTime() - return_date.getTime()
  );

  // Convert milliseconds to days
  const daysDifference = millisecondsDifference / (1000 * 60 * 60 * 24);

  // Round the result to the nearest integer
  return Math.round(daysDifference);
}

export async function convertData(data) {
  const convertedData = {
    geoLocation: data.geography_location ?? '',
    numberOfPassengers: Number(data.no_of_passengers) ?? '',
    departureDate: data.departure_date ?? '',
    returnDate: data.return_date ?? '',
    insurerName: data.insurer_name ?? '',
    noOfDays:
      (await getDaysDifference(data.departure_date, data.return_date)) ?? '',
    agent: {
      tjId: data.agent_id ?? '',
      email: data.agent_email ?? '',
      mobileNumber: data.agent_mb_number ?? '',
      name: data.agent_name
    },
    passengerDetails: []
  };

  // Dynamically handle multiple passengers
  for (let i = 1; i <= data.no_of_passengers; i++) {
    const passengerKey = `passenger_${i}`;
    convertedData.passengerDetails.push({
      key: data[`${passengerKey}_key`],
      name: data[`${passengerKey}_name`],
      geoLocation: data[`${passengerKey}_geography_location`],
      departureDate: data[`${passengerKey}_departure_date`],
      returnDate: data[`${passengerKey}_return_date`],
      birthDate: data[`${passengerKey}_birth_date`],
      age: data[`${passengerKey}_age`]
    });
  }

  return convertedData;
}

export function isEmpty(value) {
  // Check for null or undefined
  if (value === null || value === undefined) {
    return true;
  }

  // Check for empty objects or strings
  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }
  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  // If none of the above conditions are met, the value is not empty
  return false;
}

export const updatePassengerKeys = (passenger) => ({
  name: passenger.customer_name,
  id: passenger.id,
  birthDate: passenger.customer_birth_date,
  age: passenger.customer_age,
  mobileNumber: passenger.customer_mb_number,
  email: passenger.customer_email,
  gender: passenger.customer_gender,
  address: passenger.customer_address,
  nomineeName: passenger.nominee_name,
  nomineeRelationship: passenger.nominee_relationship,
  passportNumber: passenger.passport_no,
  selectedPlan: passenger.issuance_plan
});

export const transformDataIntoCheckboxFormat = (
  inputData: GeneratedPolicy[]
): singleIssuanceDataDto<SingleIssuanceTable> => {
  return {
    passengers: inputData?.map((item) => ({
      id: item.details.passengerId,
      name: item.details.passengerName,
      tjPolicyId: item.details.policyId,
      insurerPolicyId: item.details.policyId,
      sendToAgent: true,
      sendToPassenger: true,
      downloadLink: '',
      issuanceId: 'S001'
    }))
  };
};

export const convertDataLikeCheckBoxChoice = async (inputData) => {
  return inputData?.passengers?.map((passenger) => ({
    id: passenger.id,
    sendToAgent: passenger.sendToAgent,
    sendToPax: passenger.sendToPassenger
  }));
};

type ErrorParams = {
  status?: number;
  statusCode?: number;
  statusText?: string;
  code?: string;
};

/**
 * Retrieves an error message based on the provided error parameters.
 *
 * @param {ErrorParams} error - The error object containing status, statusCode, statusText, and/or code.
 * @returns {string} The appropriate error message based on the error parameters.
 *
 * @description
 * This function attempts to find the most appropriate error message by checking:
 * 1. HTTP status codes against predefined messages.
 * 2. Database-specific error codes.
 * 3. The provided statusText.
 * If no specific error is found, it returns a generic unknown error message.
 *
 * @example
 * const error = { status: 404 };
 * const message = getErrorMessage(error);
 * console.log(message); // "Not Found"
 */
export const getErrorMessage = (error: ErrorParams = {}): string => {
  const status = error.status || error.statusCode;
  console.log(status);
  

  // Look up HTTP status messages
  if (status && httpErrorMessages[status]) {
    return httpErrorMessages[status];
  }

  // Look up database-specific errors by code
  if (error.code && databaseErrorMessages[error.code]) {
    return databaseErrorMessages[error.code];
  }

  // If no specific error found, return the statusText if available
  if (error.statusText) {
    return error.statusText;
  }

  // Fallback to a generic unknown error message
  return customErrorMessages.UNKNOWN_ERROR;
};

/**
 * Groups validation errors based on their content and frequency.
 *
 * @param {string[]} errors - An array of error strings to be grouped.
 * @returns {Array<{type: string, errorCount: number, errorMessage: string}>} An array of grouped error objects.
 *
 * @description
 * This function processes an array of error strings, specifically looking for "File Validation Error" messages.
 * It groups these errors based on their content, counting occurrences of similar errors.
 * The function uses a regular expression to extract relevant parts of the error message.
 *
 * @example
 * const errors = [
 *   "File Validation Error: Column `Name` should not be empty.",
 *   "File Validation Error: Column `Email` should not be empty.",
 *   "File Validation Error: Column `Name` should not be empty."
 * ];
 * const groupedErrors = groupValidationErrors(errors);
 * // Returns:
 * // [
 * //   { type: "File Validation Error", errorCount: 2, errorMessage: "`Name` should not be empty" },
 * //   { type: "File Validation Error", errorCount: 1, errorMessage: "`Email` should not be empty" }
 * // ]
 */
export const groupValidationErrors = (errors) => {
  return errors.reduce((acc, curr) => {
    const match = curr.match(/File Validation Error:.*`(.*?)`.*(should not be empty).*/);
    if (match) {
      const errorMessage = `\`${match[1]}\` ${match[2]}`;
      const existing = acc.find((item) => item.errorMessage === errorMessage);
      if (existing) {
        existing.errorCount += 1;
      } else {
        acc.push({
          type: "File Validation Error",
          errorCount: 1,
          errorMessage
        });
      }
    }
    return acc;
  }, []);
};